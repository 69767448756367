<template>
  <div class="footprint">
    <!-- 顶部导航条 -->
    <nut-navbar
      class="header"
      @on-click-back="back"
      :leftShow="true"
      :rightShow="true"
      >{{ $t("我的足迹") }}</nut-navbar
    >

    <!-- 地图盒子 -->
    <maps class="maps"></maps>
  </div>
</template>

<script>
import maps from "../../compont/maps.vue";
export default {
  components: {
    maps,
  },
  data() {
    return {};
  },

  methods: {
    back() {
      this.$router.go("-1");
      console.log("header头部， 点击返回");
    },
  },
};
</script>

<style lang="less" scoped>
.header {
  height: 6vh;
  line-height: 6vh;
  font-size: 0.24rem;
  box-shadow: 0 0 10px 0 #cecece;
  // background-color: #add;
}
.maps {
  height: 94vh;
}
</style>