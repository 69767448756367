<template>
  <div class="footprint">
    <!-- 地图盒子 -->
    <div id="containerOne"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: null,
      polylinegg: null,
      maps: null,
    };
  },
  async mounted() {
    await this.pushData();
    await this.initMap();
  },
  async created() {},

  methods: {
    async pushData() {
      let params = new FormData();
      let userId = 1;
      params.append("userId", userId);
      let res = await this.$axios.post("/api/msg/userFootprint", params);
      // console.log(res.data);
      this.data = res.data.map((item) => {
        return new TMap.LatLng(item.latitude, item.longitude, 100);
      });
      console.log(this.data);
    },
    initMap() {
      let center = new qq.maps.LatLng(35.4, 102);
      let map = new qq.maps.Map(document.getElementById("containerOne"), {
        center: center,
        zoom: 8, // 设置地图缩放级别
        minZoom: 7, //此处设置地图的缩放级别  最小值是6
        maxZoom: 13, //此处设置地图的缩放级别  最大值是7
        // pitch: 43.5, // 设置俯仰角
        // rotation: 0, // 设置地图旋转角度
        viewMode: "2D",
      });
      let path1 = this.data;
      // let path1 = [
      //   new TMap.LatLng(35.511417, 102.019801, 1),
      //   new TMap.LatLng(35.514517, 102.017931, 100),
      //   new TMap.LatLng(35.566245, 102.063747, 100),
      // ];
      console.log(path1);
      let polyline = new qq.maps.Polyline({
        path: path1,
        strokeColor: "#111",
        strokeWeight: 5,
        editable: false,
        map: map,
      });
    },
    fun() {
      //setMap
      this.polylinegg.setVisible(true);
      if (this.polylinegg.getMap()) {
        this.polylinegg.setMap(null);
      } else {
        this.polylinegg.setMap(this.maps);
      }
    },
  },
};
</script>


<style lang="less" scoped>
#containerOne {
  width: 100%;
  height: 100%;
}

//  去掉腾讯地图logo
/deep/ #containerOne > div > div:nth-child(2) > div:nth-child(1) {
  display: none !important;
}
</style>